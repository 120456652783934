import { User } from "./user";

export interface Profile {
	username: string;
	displayName: string;
	image?: string;
	bio?: string;
	followersCount: number;
	followingCount: number;
	following: boolean;
	photos?: Photo[];
}

export class Profile implements Profile {
	constructor(user: User) {
		this.username = user.username;
		this.displayName = user.displayName;
		this.image = user.image;
	}
}

export interface Photo {
	id: string;
	url: string;
	isMain: boolean;
}

export interface UserActivity {
	id: string,
	title: string,
	category: string,
	date: Date
}


/* Note: Alternativ mit Partial<Profile> möglich (siehe StudentAssets/section 18 challenge/Solution.pdf) */
export class ProfileFormValues {
	displayName: string = '';
	bio?: string = '';

	constructor(profile?: ProfileFormValues) {
		if (profile) {
			this.displayName = profile.displayName;
			this.bio = profile.bio
		}
	}
}
