import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { Button } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import MyTextArea from "../../app/common/form/MyTextArea";
import { Profile } from "../../app/models/profile";

interface Props {
	profile: Profile;
	submitted: () => void;
}

export default observer(function ProfileForm({ profile, submitted }: Props) {

	const { profileStore: { editProfile } } = useStore();
	const { userStore } = useStore();

	function handleSubmit(values: Profile) {
		editProfile(values).then(() => {
			submitted();
			userStore.setDisplayName(values.displayName);
		}).catch(error => console.log(error));
	}

	return (
		<Formik
			initialValues={profile}
			onSubmit={(values) => handleSubmit(values)}
			validationSchema={Yup.object({
				displayName: Yup.string().required(),
			})}
		>
			{({ isSubmitting, isValid, dirty }) => (
				<Form className='ui form error' autoComplete='off'>
					<MyTextInput name='displayName' placeholder="Display Name" />
					<MyTextArea rows={5} name='bio' placeholder='Bio' />
					<Button disabled={!isValid || !dirty || isSubmitting} loading={isSubmitting} positive content='Update' type='submit' floated='right' />
				</Form>
			)}
		</Formik>
	);
});