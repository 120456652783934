import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Button, Grid, Header, Tab } from "semantic-ui-react";
import { Profile } from "../../app/models/profile"
import { useStore } from "../../app/stores/store"
import ProfileForm from "./ProfileForm";

interface Props {
	profile: Profile
}

export default observer(function ProfileAbout({ profile }: Props) {
	const { profileStore: { isCurrentUser } } = useStore();

	const [editProfileMode, setEditProfileMode] = useState(false);

	const submitted = () => {
		setEditProfileMode(false);
	}

	return (
		<Tab.Pane>
			<Grid>
				<Grid.Column width={16}>
					<Header floated='left' icon='user' content={'About ' + profile.displayName} />
					{isCurrentUser && (
						<Button floated='right' basic content={editProfileMode ? 'Cancel' : 'Edit Bio'} onClick={() => setEditProfileMode(!editProfileMode)} />
					)}
				</Grid.Column>
				<Grid.Column width={16}>
					{editProfileMode ? (
						<ProfileForm profile={profile} submitted={submitted} />
					) : (
						<span style={{ whiteSpace: 'pre-wrap' }}>{profile?.bio}</span>
					)}
				</Grid.Column>
			</Grid>
		</Tab.Pane>
	)
});